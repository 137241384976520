<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-row dense>
            <v-col cols="6">
                <v-switch v-model="item.enable" label="Habilitado" dense />
            </v-col>
            <v-col cols="6">
                <v-switch v-model="item.operate" label="Comando remotos" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <ListClient :value="item.idPoint" @change="item.idPoint = $event ? $event : null" />
            </v-col>
            <v-col cols="12">
                <ListCondominium :value="item.idCondominium" @change="item.idCondominium = $event ? $event : null" />
                <v-alert icon="warning" prominent text type="warning" dense v-if="item.policy == 'CONDOMINIUM' && !item.idCondominium">
                    Selecione o condominio.
                </v-alert>
            </v-col>
        </v-row>
        <v-row dense v-if="item.idCondominium">
            <v-col cols="6">
                <v-text-field label="Vaga" v-model="item.parkingSpace" dense />
            </v-col>
            <v-col cols="6">
                <v-text-field label="Apartamento" v-model="item.apartmentNumber" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <v-text-field label="UUID" :rules="[rules.required]" v-model="item.UUID" dense required />
            </v-col>
            <v-col cols="6">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Marca" v-model="item.brand" dense required v-bind="attrs" v-on="on" />
                    </template>
                    <span>Usado para aparecer na fatura do cartão</span>
                </v-tooltip>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Nome (link)" :rules="[rules.required, rules.linkName]" v-model="item.name" dense required />
            </v-col>
            <v-col cols="6">
                <v-select v-model="item.policy" :items="policies" :rules="[rules.required]" label="Política de acesso" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <v-autocomplete v-model="item.protocol" :items="protocols" item-value="id" item-text="name" label="Protocolo" dense :disabled="item.model == 'eBike'" />
            </v-col>
            <v-col cols="6">
                <v-autocomplete v-model="item.server" :items="servers" item-value="id" item-text="name" label="Servidor (Comando, Pagamento)" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <v-autocomplete v-model="item.model" :items="models" item-value="id" item-text="name" label="Modelo" dense :rules="[rules.required]" :disabled="models.length === 0"
                    :loading="models.length === 0" @change="changeModel" />
            </v-col>
            <v-col cols="6">
                <v-text-field v-model="item.numberPlugs" :rules="[rules.required, (v) => v <= 10 || 'Máximo 10', (v) => v >= 1 || 'Mínimo 1']" label="Número de Plugs" type="number"
                    dense :disabled="blockChangeNumberPlug" required />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-autocomplete v-model="item.idBoard" :items="boards" item-value="id" item-text="name" label="Gateway" dense :disabled="boards.length === 0 || item.model == 'AC'"
                    :loading="boards.length === 0" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-btn block :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

import protocols from "@/assets/json/protocols.json";
import servers from "@/assets/json/servers.json";
import policies from "@/assets/json/policies.json";
import models from "@/assets/json/models.json";

import ListCondominium from "@/components/shared/ListCondominium";
import ListClient from "@/components/shared/ListClient";

export default {
    name: "ChargerForm",

    directives: { mask },

    components: { ListCondominium, ListClient },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
        controlAPI: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                idCity: "",
                enable: true,
            },
            item: { ...this.itemClean },
            boards: [],
            blockChangeNumberPlug: false,
            models,
            protocols,
            servers,
            policies,
        };
    },

    mounted() {
        this.$http
            .get("api/v2/board?sort[name]=ASC")
            .then((result) => {
                this.boards = result;
            })
            .catch(() => {
                this.boards = [];
            });
    },

    watch: {
        itemToEdit: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.$nextTick(function () {
                        this.item = { ...newVal };
                    });
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                if (!this.item.idCondominium) {
                    this.item.parkingSpace = null;
                    this.item.apartmentNumber = null;
                }
                if (this.controlAPI == true) {
                    if (this.item.id) {
                        this.$http
                            .put(`api/v2/charger`, this.item.id, this.item)
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Editado com sucesso.");
                                this.$emit("save", this.item);
                            })
                            .catch(() =>
                                this.$eventHub.$emit("msgError", "Erro ao salvar informações."));
                    } else {
                        this.$http
                            .post(`api/v2/charger`, this.item)
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Salvo com sucesso.");
                                this.$emit("save", this.item);
                            })
                            .catch(() =>
                                this.$eventHub.$emit("msgError", "Erro ao salvar informações."));
                    }
                } else {
                    this.$emit("save", this.item);
                }
            }
        },
        changeModel() {
            if (this.item.model == "eBike") {
                this.item.numberPlugs = 10;
                this.item.protocol = null;
                this.blockChangeNumberPlug = true;
            } else if (this.item.model == "INC1X32" || this.item.model == "INC3X32" || this.item.model == "IME1X100A") {
                this.item.numberPlugs = 1;
                this.blockChangeNumberPlug = true;
            } else if (this.item.model == "DC" || this.item.model == "AC") {
                this.item.idBoard = null;
                this.blockChangeNumberPlug = false;
            } else {
                this.blockChangeNumberPlug = false;
            }
        },
    },
};
</script>